<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-upload v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" name="file" 
          :data="uploadParams"
          :action="uploadUrl" :beforeUpload="beforeUpload"
          :fileList="fileList"
          @change="changeFile">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" :disabled="isEdit == 1? true : false" placeholder="请选择" showSearch :filterOption="filterOption"
           v-decorator="[item.name, { rules: item.rules }]" allowClear @search="handleSearch">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'course_id',
    label: '所属课程',
    type: 'select',
    rules: [{ required: true, message: '请输入所属课程!' }],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_id'
    }
  },
  {
    name: 'ware_name',
    label: '课件名称',
    type: 'input',
    rules: [{ required: true, message: '请输入课件名称!' }]
  },
  {
    name: 'course_link',
    label: '课件',
    type: 'upload',
    rules: [{ required: true, message: '请输入课件!' }]
  },
]
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit:Number
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增课件',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: []
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑课件'
      let item = await this.getDetail(this.item.ware_id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      if (item.course_link) {
        this.fileList[0] = {
          uid: '1',
          name: item.course_link,
          status: 'done',
        }
      }
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClassCategory(obj) {
      let res = await this.$store.dispatch('searchCourseAction', {params:obj})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(ware_id) {
      let res = await this.$store.dispatch('courseWareDetailAction', {data: {ware_id}})
      return res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.ware_id = item.ware_id
          }
          params.course_link = this.fileList[0].name
          params.ware_size = this.fileList[0].size
          params.mine_type = this.fileList[0].type
          let res = await this.$store.dispatch(item ? 'courseWareUpdateAction' : 'courseWareAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },

    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    changeFile(file) {
      if (!this.fileList[0]) {
        this.fileList[0] = {
          uid: '1',
          size: file.file.size, 
          type: file.file.type, 
          name: this.uploadParams.key,
          status: 'done',
        }
      }
      this.fileList[0].name = this.uploadParams.key
    },
    handleSearch(val){
      this.getClassCategory({q:val})
    },
  }
}
</script>
