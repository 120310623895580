<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="750px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" v-decorator="[item.name, { rules: item.rules }]" style='width:100%' :min="0" />
        <a-upload v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" name="file" 
          :data="uploadParams"
          :action="uploadUrl" :beforeUpload="beforeUpload"
          :fileList="fileList"
          @change="changeFile">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" :disabled="isEdit == 1? true : false" placeholder="请选择" showSearch :filterOption="filterOption"
           v-decorator="[item.name, { rules: item.rules }]" allowClear @search="handleSearch">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <l-editor v-decorator="[item.name, { rules: item.rules }]" v-else-if="item.type === 'textarea2'" name="content" :data="editor.content"/>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'ware_name',
    label: '教案名称',
    type: 'input',
    rules: [{ required: true, message: '请输入教案名称!' }]
  },
  {
    name: 'ware_minutes',
    label: '教案时长（分钟）',
    type: 'number',
    rules: [{ required: false, message: '请输入教案时长!' }]
  },
  {
    name: 'ware_target',
    label: '教学目标',
    type: 'textarea',
    rules: [{ required: false, message: '请输入教学目标!' }]
  },
  {
    name: 'ware_content',
    label: '教案内容',
    type: 'textarea2',
    rules: [{ required: false, message: '请输入教案内容!' }]
  },
]
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
export default {
  name: 'contentModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit:Number,
    cateId:Number,
    levelId:Number,
    editContent:String
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增教案',
      visible: false,
      confirmLoading: false,
      formData,
      content:'',
      editor: {},
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: []
    }
  },
  provide() {
      return {
          parent: this
      }
  },
  async created() {
    this.visible = true
    console.log(this.cateId)
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑教案'
      let formFields = {}
      console.log(this.item)
      for (let d of formData) {
        formFields[d.name] = this.item[d.name]
      }
      this.form.setFieldsValue(formFields)

      this.editor.content = this.item['ware_content']
      this.form.setFieldsValue({ware_content:this.item['ware_content']})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClassCategory(obj) {
      let res = await this.$store.dispatch('searchCourseAction', {params:obj})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(ware_id) {
      let res = await this.$store.dispatch('courseWareDetailAction', {data: {ware_id}})
      return res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.content_id = item.content_id
          }
          params.course_cate_id = this.cateId
          params.level_id = this.levelId
          let res = await this.$store.dispatch(item ? 'courseWareUpdateCateLevelContentAction' : 'courseWareAddCateLevelContentAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideContentModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideContentModal(0)
    },

    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    changeFile(file) {
      if (!this.fileList[0]) {
        this.fileList[0] = {
          uid: '1',
          size: file.file.size, 
          type: file.file.type, 
          name: this.uploadParams.key,
          status: 'done',
        }
      }
      this.fileList[0].name = this.uploadParams.key
    },
    changeEditor(key, value) {
        this.editor[key] = value
        // console.log(value)
        this.form.setFieldsValue({ware_content:value})
    },
    handleSearch(val){
      this.getClassCategory({q:val})
    },
  }
}
</script>
