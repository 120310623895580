<template>
    <a-modal title="我的排课列表" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="确定" width="950px" @ok="handleOk" @cancel="handleCancel">
        <div class="zcxy">
            <div class="dashboard">
            <div class="clearfix table-tools">
            <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :value='rangesValue'
                            :popupStyle='{zIndex:4100}'
                            :disabled-date="disabledDateF" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-select v-model="searchParams.search.studio_ids" showSearch allowClear @change="handleChange" placeholder="请选择校区" :filterOption="filterOption" style="width:160px">
                            <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-select v-model="searchParams.search.course_type" showSearch allowClear @change="handleChangeUpdate" @search="e=>handleSearch(e,1)" placeholder="请选择课程科目" :filterOption="filterOption" style="width:160px">
                            <a-select-option v-for="(item, index) of cCategorys" :key="index" :value="item.course_cate_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" type="primary">筛选</a-button>
                    </a-form-item>
                </a-form>
            </div>
            </div>
            <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="course_class_id"
                :rowSelection="{type: 'radio', columnTitle: '选择',selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                :selection="{key: 'key'}" :customRow="clickRow"
                :columns="columns" :dataSource="list">
                <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>

                <template slot="classDate" slot-scope="text, record">
                <span>{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</span>
                </template>
            </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
            </div>
        </div>
        <courseClassContentModal v-if="editCourseContentVisible" :courseClassId="courseClassId" :contentId="contentId"/>
    </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '班级名称', dataIndex: 'class_id', key: 'class_id' ,ellipsis:true},
  { title: '上课时间', width:'260px', dataIndex: 'class_date', key: '1', scopedSlots: { customRender: 'classDate' } ,sorter:()=>{}},
  { title: '上课校区', dataIndex: 'studio_id', key: 'studio_id' ,sorter:()=>{},ellipsis:true},
  { title: '授课老师', dataIndex: 'teacher_id', key: 'teacher_id' ,sorter:()=>{},align:"center",ellipsis:true},
  { title: '课程科目', dataIndex: 'course_id', key: 'course_id' ,align:"center",ellipsis:true},
//   { title: '上课教室', dataIndex: 'room_id', key: 'room_id',sorter:()=>{},ellipsis:true},
  { title: '上课学员', dataIndex: 'reserve_count', key: '4', scopedSlots: { customRender: 'reserve_count' } ,align:"center"}
]
    import courseClassContentModal from './courseClassContentModal'
    import tableMixins from '@/common/mixins/table'
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment'
    export default {
        name:"courseClassModal",
        inject: ['parent'],
        components: {
            courseClassContentModal,
        },
        data() {
            return {
                columns,
                editCourseContentVisible: false,
                loading: false,
                courseClassId:'',
                confirmLoading: false,
                list:[],
                cCategorys: [],
                teachers: [],
                studios:[],
                studio_id:'',
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
            }
        },
        props: {
            item: Array,
            contentId: Number,
        },
        mixins: [ tableMixins , ranges ],
        async created () {
            this.visible = true
            this.paramsData.start_date = moment().format('YYYY-MM-DD')
            this.paramsData.end_date = moment().add(7,'d').format('YYYY-MM-DD')
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            await this.getStudio()
            // this.searchParams.search.studio_ids = 
            this.getCategory()
            this.getTeacher()
        },
        methods: {
            hideCourseContentModal(){
                this.editCourseContentVisible = false;
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            handleOk(){
                this.editCourseContentVisible = true;
                this.courseClassId  = this.selectedRowKeys[0];
                console.log(this.selectedRowKeys);
            },
            handleCancel(){
                this.visible = false;
                this.parent.hideCourseClassModal(0)
            },
            async getList() {
                this.loading = true
                this.searchParams.search.start_date = this.paramsData.start_date || moment().format('YYYY-MM-DD')
                this.searchParams.search.end_date = this.paramsData.end_date || moment().add(7,'d').format('YYYY-MM-DD')
                this.searchParams.customer_id = this.item
                let obj = {...this.searchParams}
                obj.search.studio_id = this.studio_id
                let res = await this.$store.dispatch('scheduleIndexAction',obj)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async getCategory(val) {
                let res = await this.$store.dispatch('searchCourseTypeAction', {params:{studio_id:this.searchParams.search.studio_ids, ...val}})
                this.cCategorys = res.data
            },
            async getTeacher(val) {
                let res = await this.$store.dispatch('searchTeacherAction', {params:{studio_id:this.searchParams.search.studio_ids, ...val}})
                this.teachers = res.data
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {all:1})
                this.studios = res.data
            },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            this.selectedRowKeys = [record.course_class_id]
                        }
                    }
                }
            },
            toScreen(){
                this.searchParams.search.start_date = this.paramsData.start_date
                this.searchParams.search.end_date = this.paramsData.end_date
                this.studio_id = this.searchParams.search.studio_ids
                this.searchParams.page = 1
                this.current = 1
                this.getList()
            },
            handleSearch(e,num){
                let obj = {"q":e}
                if(num == 1){
                    this.getCategory(obj)
                }else if(num == 2){
                    this.getTeacher(obj)
                }
            },
            handleChange(){
                this.searchParams.search.course_type = undefined
                this.searchParams.search.teacher_id = undefined
                this.getCategory()
                this.getTeacher()
                this.$forceUpdate()
            },
            handleChangeUpdate(){
                this.$forceUpdate()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>