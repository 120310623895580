<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>教案内容</a-breadcrumb-item>
    </a-breadcrumb>
    <div style="flex:1" class="main-box review" :style="{ height:wHeight}">
        <a-drawer
          placement="left"
          :closable="false"
          :visible="true"
          :mask="false"
          :get-container="false"
          :wrap-style="{ position: 'absolute',left:'-15px'}"
          >
              <div>
                  <a-form layout='inline'>
                      <a-form-item>
                          <a-tooltip placement="topLeft" >
                            <a-select v-model="course_cate_id"  @change='handleChange' style="width: 200px" showSearch :filterOption="filterOption" placeholder="请选择课程科目">
                                <a-select-option v-for="(item, index) of courseList" :key="index" :value="item.course_cate_id">{{ item.course_cate_name }}</a-select-option>
                            </a-select>
                          </a-tooltip>
                      </a-form-item>
                  </a-form>
              </div>
              <div @click="showLevelModal()" class="material-left-nav">
                  <a-icon type="plus" />
                  <span style="margin-left:10px">添加教案分类</span>
              </div>
              <div class="course_wrap">
                <vue-element-loading :active="levelLoading" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="levelList">
                  <a-list-item 
                  style='cursor:pointer;width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            padding: 12px 0 12px 10px;' 
                  :class="{ course_active: isActive == index,  course_check: isCheck == index}"
                  @mouseover="handleOver(index)"
                  @mouseout="handleOut(index)"
                  @click="handleLevelCheck(index,item.level_id)"
                  slot="renderItem" 
                  slot-scope="item, index">
                    <div class="course_box">
                      <a-icon type="folder" style="margin-right:5px;"/>
                      <span>{{item.level_name}}</span>
                      <span>( {{item.level_ware_count}} )</span>

                      <a-dropdown placement="bottomRight" style="right: 10px;cursor: pointer;">
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-icon type="more" />
                            </a>
                            <a-menu slot="overlay">
                            <a-menu-item v-if="canUpdate" @click="showLevelModal(item)">
                                编辑
                            </a-menu-item>
                            <a-menu-item v-if="canDelete" @click="toDelLevel(item)">
                                删除
                            </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <!-- <span class="talkingSkills-left-item-count">( {{item.group_discourse_count}} )</span> -->
                    </div>
                  </a-list-item>
                </a-list>
              </div>
        </a-drawer>

        <a-drawer
          v-if="levelId"
          placement="left"
          :closable="false"
          :visible="true"
          :mask="false"
          :get-container="false"
          :wrap-style="{ position: 'absolute',left:'225px'}"
          >
              <div class="course_wrap">
                <div @click="showContentModal()" class="material-left-nav">
                  <a-icon type="plus" />
                  <span style="margin-left:10px">添加教案内容</span>
              </div>
                <vue-element-loading :active="levelContentLoading" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="levelContentList">
                  <a-list-item 
                  style='cursor:pointer' 
                  :class="{ course_active: isContentActive == index,  course_check: isContentCheck == index}"
                  @mouseover="handleOver(index,2)"
                  @mouseout="handleOut(index,2)"
                  @click="handleLevelContentCheck(index,item.content_id)"
                  slot="renderItem" 
                  slot-scope="item, index">
                    <div class="course_box">
                      <a-icon type="file" style="margin-right:5px;"/>
                      <span>{{item.ware_name}}</span>
                      <!-- <span>( {{item.ware_created_by}} )</span> -->
                    </div>
                  </a-list-item>
                </a-list>
              </div>
        </a-drawer>
        <div style="display:flex">
            <div class="review-wrap" 
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <vue-element-loading :active="levelContentDetailLoading" color="#00cca2" spinner="spinner"/>
                <a-card v-if="levelContentDetail" hoverable class="review-card">
                    <h3>{{levelContentDetail.ware_name}}</h3>
                    <pre>{{levelContentDetail.ware_target}}</pre>
                    <a-divider/>
                    <div class="ql-editor" v-html="levelContentDetail.ware_content"></div>
                    <div class="review-ellipsis">
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-icon :style="{ fontSize: '24px'}" type="ellipsis" />
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item v-if="canUpdate" @click="showPrepareModal(levelContentDetail)">
                                    <a href="javascript:;">备课</a>
                                </a-menu-item>
                                <a-menu-item @click="showContentModal(levelContentDetail)">
                                    <a href="javascript:;">编辑</a>
                                </a-menu-item>
                                <a-menu-item @click="delContent(levelContentDetail,j)">
                                    <a href="javascript:;">删除</a>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </a-card>

                <a-card v-for='(item,j) in contentList' :key="j" hoverable class="review-card messages-card">
                    <div class="messages-card-nav">
                        <a-avatar :src="item.created_by_avatar" :size="35" icon="user" />
                        <div class="messages-card-name">
                            <div>{{item.created_by_name}}</div>
                            <div style="font-size: 12px;">{{item.created_at}}</div>
                        </div>
                    </div>
                    <a-divider/>
                    <!-- <h3>{{item.homework_title}}</h3> -->
                    <div v-html="item.course_content"></div>
                    <div class="review-ellipsis">
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-icon :style="{ fontSize: '24px'}" type="ellipsis" />
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item @click="showCourseContentModal(item)">
                                    <a href="javascript:;">编辑</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;">删除</a>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </a-card>
                <div style="height:100px;text-align:center">
                    <span v-if="busy">没有更多了</span>
                </div>
            </div>
        </div>

        <a-drawer
          placement="right"
          :closable="false"
          :visible="true"
          :mask="false"
          width="440" 
          :get-container="false"
          :wrap-style="{ position: 'absolute',right:'50px'}"
          v-if="levelContentDetail.content_id"
          >
          <vue-element-loading :active="levelContentCommentLoading" color="#00cca2" spinner="spinner"/>
          <a-comment>
            <!-- <a-avatar
              slot="avatar"
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              alt="Han Solo"
            /> -->
            <div slot="content">
              <a-form-item>
                <a-textarea :rows="4" :value="value" @change="handleCommentChange" />
              </a-form-item>
              <a-form-item>
                <a-button html-type="submit" :loading="submitting" type="primary" @click="handleSubmit">
                  回复
                </a-button>
              </a-form-item>
            </div>
          </a-comment>
        <a-list
            class="comment-list"
            :header="`${comment_list.length} replies`"
            item-layout="horizontal"
            :data-source="comment_list"
          >
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-comment :author="item.commnet_name" :avatar="item.commnet_avatar">
                <!-- <template slot="actions">
                  <span v-for="action in item.actions">{{ action }}</span>
                </template> -->
                <p slot="content">
                  {{ item.commnet_content }}
                </p>
                <a-tooltip slot="datetime" :title="item.comment_at">
                  <span>{{ item.comment_at}}</span>
                </a-tooltip>
              </a-comment>
            </a-list-item>
          </a-list>
        </a-drawer>

      </div>
    <editModal v-if="editVisible" :isEdit='isEdit' :item="modalData"/>
    <levelModal v-if="editLevelVisible" :isEdit='isEdit' :item="modalData" :cateId="course_cate_id"/>
    <contentModal v-if="editLevelContentVisible" :isEdit='isEdit' :item="modalData" :cateId="course_cate_id" :levelId="levelId" :editContent="editContent"/>
    <courseClassModal v-if="editCourseClassVisible" :isEdit='isEdit' :contentId="levelContentDetail.content_id"/>
    <courseClassContentModal v-if="editCourseContentVisible" :item="contentData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '课件名称', dataIndex: 'ware_name', key: 'name' ,scopedSlots: { customRender: 'file' }},
  { title: '所属课程', dataIndex: 'course_id', key: 'age'},
  { title: '课件类型', dataIndex: 'mine_type', key: '1' },
  { title: '课件大小', dataIndex: 'ware_size', key: '2' },
  { title: '添加时间', dataIndex: 'created_at', key: '3' },
  { title: '状态', dataIndex: 'course_status', key: '11', width: 80, scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', width: 60, scopedSlots: { customRender: 'action' }},
]

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from 'moment'
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import courseClassModal from './courseClassModal'
import courseClassContentModal from './courseClassContentModal'
import levelModal from './levelModal'
import contentModal from './contentModal'
import authority from '@/common/mixins/authority'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },
  computed:{
      wHeight(){
          return `${Number(this.myHeight) - 165}px`
      }
  },

  components: {
    editModal,
    levelModal,
    contentModal,
    courseClassModal,
    courseClassContentModal,
  },
  directives: { infiniteScroll },
  data() {
    return {
      busy: false,
      loading: false,
      levelLoading: false,
      levelContentLoading: false,
      levelContentDetailLoading: false,
      levelContentCommentLoading: false,
      courseLoading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      editContent:'',
      list: [],
      contentList: [],
      courseList:[],
      levelList:[],
      levelContentList:[],
      levelContentDetail:'',
      current: 1,
      comment_list:[
        {
          actions: ['Reply to'],
          author: 'Han Solo',
          avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
          datetime: moment().subtract(1, 'days'),
        },
        {
          actions: ['Reply to'],
          author: 'Han Solo',
          avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
          datetime: moment().subtract(2, 'days'),
        },
      ],
      comment_list:[],
      classCategorys: [],
      courses: [],
      searchData: {},
      contentData: {},
      isEdit:0,
      visible: false,
      editVisible: false,
      editLevelVisible: false,
      editCourseContentVisible: false,
      editLevelContentVisible: false,
      editCourseClassVisible: false,
      authType:['education','ware'],
      isActive:0,
      isContentActive:0,
      isCheck:0,
      isContentCheck: 0,
      course_cate_id:'',
      levelId:'',
      content_id:'',

      comments: [],
      submitting: false,
      value: '',
    }
  },
  mixins: [ authority ],
  created() {
    this.myHeight = this.windowHeight()
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    this.getCourseList()
  },

  methods: {
    async handleSubmit() {
      if (!this.value) {
        return;
      }
      this.submitting = true;

      try{
        let params = {content_id:this.levelContentDetail.content_id,comment_content:this.value,comment_entity:this.levelContentDetail.comment_entity};
        let res = await this.$store.dispatch('courseWareCateLevelContentAddCommentAction', { data: params })
        this.getContentComment(this.levelContentDetail.content_id)
        this.value = '';
        // console.log(res)
      }catch(err){
        console.log(err)
      }

      this.submitting = false;

      // setTimeout(() => {
      //   this.submitting = false;
      //   this.comment_list = [
      //     {
      //       actions: ['Reply to'],
      //       author: 'Han Solo',
      //       avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      //       content: this.value,
      //       datetime: moment(),
      //     },
      //     ...this.comment_list,
      //   ];
      //   this.value = '';
      // }, 1000);
    },
    handleCommentChange(e) {
      this.value = e.target.value;
    },

    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    windowHeight() { 
        var de = document.documentElement
        return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
    },
    closeSearch() {
      this.visible = false
    },
    async toDelLevel(item,index){
      let that = this
        this.$confirm({
            title: `确定要删除该教案分类?`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
            return new Promise(async (resolve, reject) => {
                await that.$store.dispatch('courseWareDelCateLevelAction', {data:{ level_id: item.level_id }})
                .then(res=>{
                    that.list.splice(index,1)
                    that.$message.success('操作成功~')
                    that.handleChange(that.course_cate_id)
                    resolve(res)
                })
                .catch(err=>{
                    that.$message.error('操作失败~')
                    resolve(err)
                })
            }).catch(error => console.log(error))
            }
        })
    },
    delContent(item,index) {
        let that = this
        this.$confirm({
            title: `确定要删除该教案内容?`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
            return new Promise(async (resolve, reject) => {
                await that.$store.dispatch('courseWareDelCateLevelContentAction', {data:{ content_id: item.content_id }})
                .then(res=>{
                    that.list.splice(index,1)
                    that.$message.success('操作成功~')
                    that.getContentList(that.levelId)
                    resolve(res)
                })
                .catch(err=>{
                    that.$message.error('操作失败~')
                    resolve(err)
                })
            }).catch(error => console.log(error))
            }
        })
    },
    async handleChange(e){
      this.course_cate_id = e;
      this.levelLoading = true;
      let res = await this.$store.dispatch('courseWareCateLevelAction', { data: {course_cate_id:e} })
      this.levelList =  res.data.level_list
      this.levelLoading = false;
      if(this.levelList.length>0){
        this.levelId = this.levelList[0].level_id
        this.getContentList(this.levelList[0].level_id)
      }else{
        this.levelId = ''
        this.levelContentList =[];
      }
    },
    async getCourses(obj) {
      let res = await this.$store.dispatch('searchCourseAction', {params:obj})
      this.courses = res.data
    },
    async getContentList(level_id){
      this.levelContentLoading = true;
      let res = await this.$store.dispatch('courseWareCateLevelContentAction', { data: {level_id:level_id} })
      this.levelContentList =  res.data.level_content_list
      if(this.levelContentList.length>0){
        this.getContentDetail(this.levelContentList[0].content_id)
      }
      this.levelContentLoading  = false;
    },
    async getContentDetail(content_id){
      this.levelContentDetailLoading = true;
      let res = await this.$store.dispatch('courseWareCateLevelContentDetailAction', { data: {content_id:content_id} })
      this.levelContentDetail =  res.data.level_content_detail
      this.levelContentDetail.ware_content = this.handleHtml(this.levelContentDetail.ware_content)
      this.contentList = [];
      this.searchParams.page = 1;
      this.getCourseContent(content_id)
      this.getContentComment(content_id)
      this.levelContentDetailLoading = false
    },
    async getContentComment(content_id){
      this.levelContentCommentLoading = true;
      let res = await this.$store.dispatch('courseWareCateLevelContentCommentAction', { data: {content_id:content_id} })
      this.comment_list =  res.items
      this.levelContentCommentLoading = false
    },
    handleHtml(rich) {
      if(rich){
        // 使用正则表达式匹配所有图片
        const reg = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
        const match = rich.match(reg);
      
        if (match) {
          // 循环图片数组
          for (let i = 0; i < match.length; i++) {
            const item = match[i];
            const len = item.length;
            const _str = item.slice(0, len - 1) + " preview=\"0\"/>";// 追加 preview 之后的img
            rich = rich.replace(item, _str);
          }
        }
      }
      // console.log("处理之后", rich);
      return rich;
    },
    async getCourseContent(content_id){
      // this.levelContentDetailLoading = true;
      // console.log('yes content');
      this.searchParams.search.content_id = content_id
      let res = await this.$store.dispatch('courseWareCourseContentAction', { data:this.searchParams} )
      let list = []
      res.items.forEach((v, i) => {
        v.course_content = this.handleHtml(v.course_content)
        list.push(v);
      })

      if(res.items.length >0){
          this.contentList = [...this.contentList,...list]
      }else{
          this.busy = true
      }
      this.$previewRefresh()
      this.pageParams = res._meta
      // console.log(res)
      // this.levelContentDetail =  res.data.level_content_detail
      // this.levelContentDetailLoading = false
    },
    async getList(id) {
      return true;
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.course_cate_id = this.course_cate_id
      let res = await this.$store.dispatch('courseWareAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async getCourseList(){
      this.courseLoading = true
      let res = await this.$store.dispatch('courseWareCateAction',{})
      this.courseList = res.data
      this.courseLoading = false
      // this.course_cate_id = 4
      // console.log(this.course_cate_id)
      if(this.courseList.length > 0){
        this.course_cate_id = this.courseList[0].course_cate_id
        this.handleChange(this.course_cate_id)
        // this.searchParams.search.course_cate_id = this.course_cate_id
        // console.log(this.course_cate_id)
        // this.getList()
      }
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    reset() {
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },
    showPrepareModal(item){
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      // console.log('prepare.modal')
      this.editCourseClassVisible=true;
      // this.modalData = item
      // this.editLevelVisible = true;
    },
    handleInfiniteOnLoad(){
        if(this.searchParams.page != 0){
            if(!this.busy){
                this.getCourseContent(this.levelContentDetail.content_id)
            }
        }
        this.searchParams.page++
    },
    showCourseContentModal(item){
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.contentData = item
      this.editCourseContentVisible = true;
    },
    hideCourseContentModal(){
      this.editCourseContentVisible = false;
    },
    showLevelModal(item){
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.editLevelVisible = true;
    },
    showContentModal(item){
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.editLevelContentVisible = true;
    },
    hideCourseClassModal(){
      this.editCourseClassVisible = false;
    },
    hideContentModal(){
      this.editLevelVisible = false;
      this.editLevelContentVisible = false;
      this.getContentList(this.levelId)
      this.getContentDetail(this.content_id)
    },
    hideLevelModal(){
      this.editLevelVisible = false;
      this.editLevelContentVisible = false;
      this.handleChange(this.course_cate_id)
    },
    changeEditor(key, value) {
        // this.editor[key] = value
        this.editContent = value;
        // this.form.setFieldsValue({ware_content:value})
    },
    showEditModal(item) {
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    changeStatus(record) {
      if (record.course_status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    deleteItem(item) {
      let that = this;
      return new Promise(async (resolve, reject) => {
        let res = await that.$store.dispatch('courseWareDeleteAction', { data: { ware_id: item.ware_id } })
        if (res) {
          item.course_status = 0
          resolve(res)
        }
      }).catch(error => console.log(error));
    },
    recoveryItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
        let res = await that.$store.dispatch('courseWareRecoveryAction', { data: { ware_id: item.ware_id } })
        if (res) {
          item.course_status = 1
          resolve(res)
        }
      }).catch(error => console.log(error));
    },
    toDown(val){
        const link = document.createElement('a')
        link.href = val.link
        link.click()
    },
    handleSearch(val){
      this.getCourses({q:val})
    },
    handleOver(index,type){
      if(type){
        this.isContentActive=index
      }else{
        this.isActive = index
      }
    },
    handleOut(){
      this.isActive = -1
      this.isContentActive = -1
    },
    handleLevelCheck(index,id){
      this.isCheck = index
      this.isContentCheck = 0;
      this.levelId = id;
      // console.log(this.levelId);
      this.getContentList(id)
    },
    handleLevelContentCheck(index,id){
      this.isContentCheck = index
      this.content_id = id;
      this.getContentDetail(id)
    }
  }
}
</script>
<style lang="scss">

  .messages{
      &-list{
          height: calc(100vh - 205px);
          overflow-y: auto;
      }
      &-search{
          width: 700px;
          margin: 0 auto;
      }
      &-send{
          width: 700px;
          margin: 0 auto;
          background: #fff;
          padding: 10px;
          border-radius: 2px;
          border: 1px solid #e8e8e8;
          textarea{
              resize: none;
          }
          &-icon{
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 5px;
          }
          &-iconL{
              display: flex;
              align-items: center;
              .ant-btn{
                  border: none;
                  box-shadow:none;
                  padding: 0px;
              }
          }
          &-upload{
              margin-right: 15px;
          }
          &-img{
              display: flex;
              flex-wrap: wrap;
              img{
                  height: 60px;
              }
          }
          &-imgBox{
              margin-right: 5px;
              margin-bottom: 5px;
              position: relative;
          }
          &-audio{
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              &-icon{
                  margin-left: 10px;
                  font-size: 24px;
                  cursor: pointer;
              }
              &-loading{
                  width: 300px;
                  height: 54px;
              }
          }
          &-video{
              display: inline-block;
              position: relative;
              margin-right: 5px;
              margin-bottom: 5px;
          }
          &-video-icon{
              position: absolute;
              top: 15px;
              left: 15px;
              background: #000;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
          }
          &-close{
              width: 14px;
              height: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(250,250,250,.5);
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 0;
          }
          &-loading{
              width: 60px;
              height: 60px;
          }
      }
      &-card{
          width: 700px;
          margin: 0 auto;
          &-nav{
              display: flex;
          }
          &-name{
              margin-left: 20px;
          }
          &-mar{
              margin-left: 55px;
              margin-top: 10px;
          }
          &-text{
              white-space: normal;
              word-break: break-all;
          }
          &-img{
              display: flex;
              flex-wrap: wrap;
              img{
                  height: 60px;
                  margin-right: 5px;
                  margin-bottom: 5px;
              }
          }
          &-video{
              display: inline-block;
              position: relative;
          }
          &-video-icon{
              position: absolute;
              top: 15px;
              left: 15px;
              background: #000;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
          }
          &-rate{
              display: flex;
              align-items: center;
          }
          &-rateImg{
              width: 100px;
              margin-right: 50px;
          }
          &-rateBox{
              display: flex;
              align-items: center;
          }
          &-rateName{
              width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis
          }
      }
      &-comments{
          width: 700px;
          margin: 0 auto;
          background: #fff;
          border: 1px solid #e8e8e8;
          border-top: none;
          padding: 12px 24px;
          textarea{
              resize: none;
              border:1px solid #fff;
          }
          &-reply{
              border: 1px solid #e8e8e8;
              padding: 5px;
              border-radius: 2px;
              margin-bottom: 5px;
          }
          &-btn{
              text-align: right;
              margin-top: 5px;
          }
      }
      .ant-list-item{
          border-bottom: none;
          display: block;
      }
  }

  .review{
    position: relative;
    height: 650px;
    overflow: hidden;
    &-wrap{
        flex: 1;
        height: 650px;
        overflow-y: auto;
    }
    &-card{
        width: 600px;
        margin:0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
    }
    &-video{
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
    &-video-item{
        position: relative;
    }
    &-video-icon{
        position: absolute;
        top: 23px;
        left: 23px;
        background: #000;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
    }
    &-statistics{
        display: flex;
        justify-content: flex-end;
    }
    &-statistics-item{
        display: flex;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
        span{
            margin-left:5px;
        }
    }
    &-ellipsis{
        position: absolute;
        top: -12px;
        right: -40px;

    }
  }
  .course_wrap{
    // height: 658px;
    overflow-y: auto;
  }
  .course_wrap_title{
    padding: 10px;
    border-bottom: 1px dashed #e8e8e8;
  }
  .course_active{
    background: #e6fff6;
  }
  .course_check{
    background: #e6fff6;
  }
  .course_box{
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    span{
      margin-left:10px;
    }
  }
  .material-left-nav{
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  .review{
        position: relative;
        height: 850px;
        overflow: hidden;
        &-wrap{
            flex: 1;
            height: 850px;
            overflow-y: auto;
        }
        &-card{
            width: 600px;
            margin:0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            position: relative;
            img{
              max-width: 520px;
            }
        }
        &-video{
            display: flex;
            align-items: center;
            margin: 10px 0;
        }
        &-video-item{
            position: relative;
        }
        &-video-icon{
            position: absolute;
            top: 23px;
            left: 23px;
            background: #000;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
        }
        &-statistics{
            display: flex;
            justify-content: flex-end;
        }
        &-statistics-item{
            display: flex;
            align-items: center;
            margin-left: 8px;
            cursor: pointer;
            span{
                margin-left:5px;
            }
        }
        &-ellipsis{
            position: absolute;
            top: -12px;
            right: -40px;

        }
    }
  </style>